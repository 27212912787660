<template>
    <v-container>
        <v-card class="pa-3" elevation-1 >
            <v-toolbar class="primary white--text text-h5">
                Choose Person In Charge
            </v-toolbar>
            <div>
                <v-autocomplete small-chips deletable-chips multiple item-text="staff_name" :items="picOptions" v-model="picSelected" dense outlined label="Person In Charge" return-object>
                    <template v-slot:item="{item}">
                        {{ item.staff_name }} ( {{ item.role }} )
                    </template>
                </v-autocomplete>
            </div>
        </v-card>
    </v-container>
</template>
<script>
    export default{
        props:[
            'companies'  
        ],
        data: () => ({
            picSelected:null,
            picOptions:null,
            api:{
                isSuccesful:false,
                isLoading:false,
                isError:false,
                error:null,
                sucess:null,
                url:null,
            }
        }),

        created() {
            this.api.callbackReset = () => 
            {
                this.api.isLoading = true;
                this.api.isError = false;
            };
            this.api.callbackError = (e) =>
            {
                this.api.isLoading = false;
                this.api.isError = true;
                this.api.error = e;
            };
            this.api.callbackSuccess = (resp) =>
            {
                if(resp.class=="getUserMultipleRole"){
                    
                    this.picOptions = resp.data;
                }
            
                this.api.isSuccesful = true;
                this.api.isError = false;
                this.api.isLoading = false;

            }
        },
        mounted() {
            this.fetch();
        },
        methods:{
            fetch(){
                let fetchPICApi = this.fetchPIC();
                this.$api.fetch(fetchPICApi);
            },
            fetchPIC(){
                let tempApi = this.$_.clone(this.api);
                tempApi.method = "POST";
                tempApi.url = process.env.VUE_APP_SERVER_API+"/user/role/multiple";
                tempApi.params={
                    'roles':["salesperson","customer_service","vision_admin","sales_team_lead","sales_manager"],  
                };
                return tempApi;
            }
        },

    }
</script>